import * as React from "react";
import Layout from "../components/layout";
import GalleryModal from "../components/GalleryModal";
import Seo from "../components/seo";
import { ImageModalContext } from "../context/ImageModalContext";
import { isMobile } from "react-device-detect";
import SanityImage from "gatsby-plugin-sanity-image";

const SingleProject = ({ pageContext }) => {
  const [, setImageModal] = React.useContext(ImageModalContext);

  return (
    <GalleryModal
      backButton
      project={pageContext}
      facts="Duis mattis elit ac malesuada blandit. Praesent in est vel justo dapibus dictum id at nulla. Cras nulla eros, ultrices eu diam gravida, pulvinar blandit nisi. Nulla sodales euismod ultrices. Etiam tellus ante, pulvinar non purus vel, feugiat pharetra leo. In hac habitasse platea dictumst. Donec dapibus turpis ac arcu pharetra, non fermentum arcu tincidunt. Praesent urna lectus, consequat quis diam non, fermentum pellentesque ante. Integer commodo erat est, ac facilisis justo vestibulum vel. Morbi ullamcorper lorem eget maximus sollicitudin. Aenean sed rutrum felis. Fusce ut quam in ex lobortis faucibus. Nullam eu venenatis tellus. Cras commodo eget massa ut iaculis. Vivamus nec enim laoreet, fermentum nisi id, feugiat neque. Cras augue ligula, blandit ut vulputate ut, suscipit in est."
    >
      <Seo title={pageContext.name} />
      {pageContext.pictures.map((picture) => (
        <div
          style={{
            objectFit: "cover",
            overflowY: "hidden",
            overflowX: "hidden",
            height: "100%",
            flexShrink: 0,
          }}
          onClick={
            isMobile
              ? () => {
                  setImageModal({
                    imageUrl: picture.asset.url,
                    visible: true,
                    asset: picture.asset,
                  });
                }
              : null
          }
        >
          <SanityImage
            asset={picture.asset}
            {...picture.asset}
            style={{ width: "auto", height: "100%" }}
            alt={`Projektbild ${pageContext.name}`}
          ></SanityImage>
        </div>
      ))}
    </GalleryModal>
  );
};

SingleProject.Layout = Layout;

export default SingleProject;
